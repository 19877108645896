import { memo, useMemo } from 'react';

import ModalSidebar from '@Modals/_components/ModalSidebar';
import RegionPosition from './elems/RegionPosition';
import useModalWindowTranslation from '@Queries/useModalWindowTranslation';
import styles from './AllRegionsModal.module.css';

import type { FC } from 'react';
import type { RegionHintData } from '@Types/Region';
import type { ModalSidebarProps } from '@Modals/_components/ModalSidebar';

export interface RegionsByCharsData {
  char: string;
  regions: RegionHintData[];
}

export interface AllRegionsModalData {
  regions: RegionHintData[];
}

export type AllRegionsModalProps = ModalSidebarProps<AllRegionsModalData>;

const AllRegionsModal: FC<AllRegionsModalProps> = (props) => {
  const { modal, ...restProps } = props;
  const { t, isPending } = useModalWindowTranslation({ modalId: modal.id });

  const regionsByChars: RegionsByCharsData[] = useMemo(() => {
    const result = [];
    let firstChar = 'А';
    let group = { char: firstChar, regions: [] };

    modal.data.regions.forEach((region) => {
      const beginning = region.name.slice(0, 1);
      if (beginning !== firstChar) {
        firstChar = beginning;
        result.push(group);
        group = { char: beginning, regions: [region] };
      } else {
        group.regions.push(region);
      }
    });

    return result;
  }, [modal.data.regions]);

  const columns: RegionsByCharsData[][] = useMemo(() => {
    const result = [];
    const middlePosition = Math.floor(regionsByChars.length / 2);
    result.push(regionsByChars.slice(0, middlePosition + 1));
    result.push(regionsByChars.slice(middlePosition + 1));
    return result;
  }, [regionsByChars]);

  if (isPending) return null;

  return (
    <ModalSidebar
      {...restProps}
      title={t('title')}
      view='default'
      modal={modal}
      cnWrapperContent={styles.wrapperContent}
    >
      <div className={styles.content}>
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} className={styles.column}>
            {column.map((item, itemIndex) => (
              <RegionPosition
                key={itemIndex}
                char={item.char}
                regions={item.regions}
                className={styles.regionPosition}
              />
            ))}
          </div>
        ))}
      </div>
    </ModalSidebar>
  );
};

export default memo(AllRegionsModal);
