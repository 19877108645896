import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import styles from './RegionPosition.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { RegionHintData } from '@Types/Region';

export interface RegionPositionProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  char: string;
  regions: RegionHintData[];
}

const RegionPosition: FC<RegionPositionProps> = (props) => {
  const { className, char, regions } = props;

  return (
    <div className={cn(styles.regionPosition, className)}>
      <div className={styles.char}>{char}</div>
      <div>
        {regions.map((region) => (
          <Link
            className={styles.link}
            view='primary'
            key={region.url}
            region={region}
            to='/site/site-map-full'
          >
            {region.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default memo(RegionPosition);
